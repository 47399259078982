import React from 'react'
import cx from 'classnames'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
    to,
    children,
    external = false,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    className,
}) => {
    const handleClick = (e) => {
        if (typeof clickHandler === 'function') {
            clickHandler(e)
            return true
        }
    }

    if (!to) {
        return (
            <div className={className} onClick={handleClick}>
                {children}
            </div>
        )
    }

    if (external || typeof clickHandler === 'function') {
        return (
            <a
                href={to}
                target="_blank"
                rel="noreferrer noopener"
                className={cx('cursor-pointer', className)}
                onClick={handleClick}
            >
                {children}
            </a>
        )
    } else {
        return (
            <GatsbyLink
                {...{
                    to,
                    className: cx('cursor-pointer', className),
                    onMouseEnter,
                    onMouseLeave,
                }}
            >
                {children}
            </GatsbyLink>
        )
    }
}

export default Link
