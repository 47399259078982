import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import { languages, functions } from '@config'
import { useMenuState, useLightboxState } from '@context'

import arrowRight from '@images/global/interface-arrow-right.svg'

import Link from '@components/Link'
import SVG from '@components/SVG'

import './style.scss'

const { processPath, isHome } = functions

const GetHeaderAssets = () => {
    const { logo } = useStaticQuery(graphql`
        query getHeaderAssets {
            logo: file(relativePath: { eq: "global/logo.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400, formats: [AUTO, WEBP, AVIF])
                }
            }
        }
    `)

    return { logo }
}

const Header = ({ globalConfig, locale, location }) => {
    const { logo } = GetHeaderAssets()
    const { menuOpen, setMenuOpen } = useMenuState()
    const { lightboxOpen } = useLightboxState()
    const [showLangOptions, setShowLangOptions] = useState(false)
    const locales = globalConfig?.locales || []
    const headerNavigationLinks = globalConfig?.headerNavigationLinks

    const downloadButtonLink = globalConfig?.downloadButtonLink
    const downloadButtonLabel = globalConfig?.downloadButtonLabel

    const _language = languages?.find(
        ({ code }) => code?.toLowerCase() === locale?.toLowerCase()
    )

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <div
            className={`flex items-center justify-between w-full pl-8 fixed top-0 left-0 z-60 ${
                lightboxOpen ? 'pointer-events-none' : 'pointer-events-auto'
            }`}
        >
            <Link to={`/${locale === 'en-GB' ? '' : locale.toLowerCase()}`}>
                <GatsbyImage
                    image={getImage(logo)}
                    className={`header__logo ${
                        menuOpen || lightboxOpen
                            ? 'opacity-0 invisible'
                            : 'opacity-100 visible'
                    } absolute left-8 top-0 w-40 md:w-48 my-6 z-60 transition-all`}
                    alt="Star Wars: Hunters"
                    style={{ position: 'absolute' }}
                />
            </Link>

            <div className="inline-flex items-center justify-end">
                <div
                    className={`header__inner flex flex-col justify-center lg:block w-full lg:w-auto h-full lg:h-auto fixed lg:relative top-0 lg:top-auto left-0 lg:left-auto bg-regalblue bg-opacity-80 lg:bg-opacity-50 rounded-b-lg border-b border-x lg:border-mayablue ${
                        menuOpen && !lightboxOpen
                            ? 'opacity-100 visible pointer-events-auto'
                            : 'opacity-0 invisible pointer-events-none'
                    } ${
                        !lightboxOpen
                            ? 'lg:opacity-100 lg:visible lg:pointer-events-auto'
                            : ''
                    }`}
                >
                    <span className="w-full h-full absolute top-0 left-0 rounded-bl-lg overflow-hidden">
                        <span className="hidden lg:block w-px h-80 absolute top-1/2 left-12 transform -translate-y-1/2 -rotate-45 origin-center bg-mayablue z-0" />
                        <span className="hidden lg:block w-px h-80 absolute top-1/2 left-1/2 transform -translate-y-1/2 -rotate-45 origin-center bg-mayablue z-0" />
                        <span className="hidden lg:block w-px h-80 absolute top-1/2 right-28 transform -translate-y-1/2 rotate-45 origin-center bg-mayablue z-0" />
                        <span className="hidden lg:block w-px h-80 absolute top-1/2 right-0 transform -translate-y-1/2 rotate-45 origin-center bg-mayablue z-0" />
                        <span className="hidden lg:block w-60 h-60 absolute -bottom-4 left-16 origin-center border border-mayablue rounded-full z-0" />
                        <span className="hidden lg:block w-72 h-72 absolute -top-4 left-1/2 origin-center border border-mayablue rounded-full z-0" />
                        <span className="hidden lg:block w-96 h-96 absolute bottom-4 left-2/3 origin-center border border-mayablue rounded-full z-0" />
                    </span>

                    <div className="flex items-center justify-center lg:justify-end px-6 relative">
                        <ul className="flex flex-col lg:flex-row items-center justify-center lg:justify-end">
                            {headerNavigationLinks?.map(
                                ({ title, name, slug, locale }, index) => (
                                    <li key={index}>
                                        <Link
                                            to={processPath(slug, locale)}
                                            onClick={() => setMenuOpen(false)}
                                        >
                                            <div
                                                className={`${
                                                    [
                                                        `/${slug}`,
                                                        `/${locale}/${slug}`,
                                                    ].indexOf(
                                                        location?.pathname
                                                    ) >= 0 || isHome(slug)
                                                        ? 'outline-heading text-white active'
                                                        : 'text-orange2 hover:text-white'
                                                } header__menu-item hover:outline-heading mayablue small block w-full lg:w-auto p-6 font-semibold text-3xl lg:text-xl uppercase italic`}
                                                data-text={title || name}
                                            >
                                                <span className="hover:outline-heading__inner">
                                                    {title || name}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>

                        {locales.length > 0 ? (
                            <div
                                className={`flex items-center flex-col ml-8 w-44 fixed lg:relative top-10 left-0 lg:top-auto lg:left-auto font-display font-bold text-lg text-regalblue border-l border-regalblue text-center ${
                                    showLangOptions
                                        ? 'shadow-none'
                                        : 'shadow-lg'
                                }   z-90`}
                            >
                                <button
                                    type="button"
                                    className="header__current-lang flex items-center justify-start w-full h-6 font-bold bg-white outline-none"
                                    onClick={() =>
                                        setShowLangOptions(!showLangOptions)
                                    }
                                >
                                    <div className="inline-block w-6 h-6 relative text-center bg-regalblue">
                                        <SVG
                                            src={arrowRight}
                                            className={`w-1.5 h-auto -ml-px absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 origin-center ${
                                                showLangOptions
                                                    ? '-rotate-90'
                                                    : 'rotate-90'
                                            } transition-all text-white`}
                                        />
                                    </div>
                                    <span className="block pl-2 pr-4 font-semibold uppercase italic">
                                        {_language?.localLabel}
                                    </span>
                                </button>
                                <div
                                    className={`${
                                        showLangOptions
                                            ? 'opacity-100 visible pointer-events-auto'
                                            : 'opacity-0 invisible pointer-events-none'
                                    } w-full absolute top-full left-0 transition`}
                                >
                                    {languages.map(
                                        (
                                            {
                                                localLabel,
                                                code,
                                                default: _default,
                                            },
                                            index
                                        ) => (
                                            <Link
                                                key={index}
                                                to={
                                                    _default
                                                        ? `/`
                                                        : `/${code.toLowerCase()}`
                                                }
                                                className={`flex items-center justify-center h-9 ${
                                                    code?.toLowerCase() ===
                                                    locale?.toLowerCase()
                                                        ? 'text-orange2 pointer-events-none cursor-auto'
                                                        : ''
                                                } hover:text-orange2 text-sm text-center text-white bg-regalblue bg-opacity-80 transition duration-200 whitespace-nowrap`}
                                            >
                                                {localLabel}
                                            </Link>
                                        )
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <span className="hidden lg:flex items-center mt-2 absolute top-full left-2/3">
                        <span className="w-1 h-1 top-full left-2 bg-mayablue" />
                        <span className="w-1 h-1 ml-1 top-full left-2 bg-mayablue" />
                    </span>
                </div>

                {downloadButtonLink && downloadButtonLabel ? (
                    <div
                        className={`hidden sm:block mr-24 lg:mr-0 relative z-0 ${
                            menuOpen || lightboxOpen
                                ? 'opacity-0 invisible pointer-events-none'
                                : 'opacity-100 visible pointer-events-auto'
                        } transition-all`}
                    >
                        <a
                            href={downloadButtonLink}
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                            className="download-btn group/button inline-flex items-center justify-center pt-[2.1rem] pb-[1.25rem] lg:py-6 px-4 lg:px-6 ml-4 font-semibold text-lg uppercase italic bg-orange3 hover:bg-yellow rounded-b-xl lg:rounded-br-none transition-colors ease-in-out"
                        >
                            {downloadButtonLabel}
                        </a>

                        <span className="block w-1 h-1 absolute top-full left-3/4 mt-2 bg-orange3 group-hover/button:bg-yellow pointer-events-none transition-colors ease-in-out"></span>
                        <span className="block w-1 h-1 absolute top-full left-3/4 mt-2 ml-2 bg-orange3 group-hover/button:bg-yellow pointer-events-none transition-colors ease-in-out"></span>
                    </div>
                ) : null}
            </div>

            <button
                className="flex flex-col lg:hidden items-center justify-center w-16 h-16 fixed top-4 right-4 text-center bg-regalblue bg-opacity-50 border border-mayablue rounded-lg z-60 overflow-hidden"
                onClick={handleMenuToggle}
            >
                <span
                    className={`h-1 w-6 bg-orange3 transform skew-x-12 ${
                        menuOpen ? '-skew-x-12 translate-x-1' : ''
                    } transition-all`}
                />
                <span
                    className={`h-1 mt-1 bg-orange3 transform ${
                        menuOpen
                            ? 'w-2 skew-x-12 -translate-x-0'
                            : 'w-4 -skew-x-12 -translate-x-0.5'
                    } transition-all`}
                />
                <span
                    className={`h-1 mt-1 bg-orange3 transform ${
                        menuOpen
                            ? 'w-5 -skew-x-12 -translate-x-1.5'
                            : 'w-6 skew-x-12'
                    } transition-all`}
                />
            </button>
        </div>
    )
}

export default Header
