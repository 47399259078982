import React, { useEffect } from 'react'

import close from '@images/global/close.svg'
import interfaceArrowLeft from '@images/global/interface-arrow-left.svg'
import interfaceArrowRight from '@images/global/interface-arrow-right.svg'

import { useModalState } from '@context'

import SVG from '@components/SVG'

const Modal = () => {
    const {
        modalOpen,
        setModalOpen,
        modalComponents = [],
        setModalComponents,
        activeModalComponent,
        setActiveModalComponent,
    } = useModalState()
    const Component =
        modalComponents?.[activeModalComponent?.[0]]?.[
            activeModalComponent?.[1]
        ]
    const componentCount = modalComponents?.[activeModalComponent?.[0]]?.length

    const handleModalClose = () => {
        setModalOpen(false)

        setTimeout(() => {
            setModalComponents(null)
        }, 250)
    }

    const handlePrev = () => {
        if (componentCount > 1) {
            setActiveModalComponent([
                activeModalComponent?.[0],
                activeModalComponent?.[1] === 0
                    ? componentCount - 1
                    : activeModalComponent?.[1] - 1,
            ])
        }
    }

    const handleNext = () => {
        if (componentCount > 1) {
            setActiveModalComponent([
                activeModalComponent?.[0],
                activeModalComponent?.[1] > componentCount - 2
                    ? 0
                    : activeModalComponent?.[1] + 1,
            ])
        }
    }

    const handleKeyDown = ({ keyCode }) => {
        if (keyCode === 27) handleModalClose()
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [modalOpen])

    return (
        <div
            className={`flex flex-col items-center justify-center w-full h-full p-10 pb-20 xl:p-20 fixed top-0 left-0 bg-black bg-opacity-90 text-center text-white transition-all ${
                modalOpen
                    ? 'opacity-100 visible pointer-events-auto'
                    : 'opacity-0 invisible pointer-events-none'
            } z-90`}
        >
            {Component ? <Component /> : null}

            {componentCount > 1 ? (
                <>
                    <button
                        type="button"
                        className="group flex items-center justify-center w-14 h-14 absolute top-1/2 left-4 transform -translate-y-1/2 text-center"
                        onClick={handlePrev}
                    >
                        <SVG
                            src={interfaceArrowLeft}
                            className="w-4 h-auto text-orange3 group-hover:text-white transition"
                        />
                    </button>
                    <button
                        type="button"
                        className="group flex items-center justify-center w-14 h-14 absolute top-1/2 right-4 transform -translate-y-1/2 text-center"
                        onClick={handleNext}
                    >
                        <SVG
                            src={interfaceArrowRight}
                            className="w-4 h-auto text-orange3 group-hover:text-white transition"
                        />
                    </button>
                </>
            ) : null}

            <button
                type="button"
                className="flex items-center justify-center w-14 h-14 absolute top-4 right-4 text-center"
                onClick={() => handleModalClose()}
            >
                <SVG src={close} className="w-6 h-auto text-white" />
            </button>
        </div>
    )
}

export default Modal
