import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const GetDefaults = () => {
    const { favicon, site } = useStaticQuery(graphql`
        query getDefaults {
            favicon: datoCmsFaviconMetaTags {
                tags
            }
            site: datoCmsSite {
                noIndex
                globalSeo {
                    facebookPageUrl
                    siteName
                    titleSuffix
                    twitterAccount
                    fallbackSeo {
                        description
                        image {
                            url(
                                imgixParams: {
                                    w: "1200"
                                    h: "630"
                                    fit: "crop"
                                }
                            )
                        }
                        title
                        twitterCard
                    }
                }
            }
        }
    `)

    return {
        ...favicon,
        ...site,
    }
}

const Meta = ({ seo, noIndex, schema = [] }) => {
    const defaults = GetDefaults()

    const title = seo?.title
        ? `${seo?.title}${defaults?.globalSeo?.titleSuffix}`
        : `${seo?.recordTitle || defaults?.globalSeo?.siteName}${
              defaults?.globalSeo?.titleSuffix
          }`
    const description =
        seo?.description || defaults?.globalSeo?.fallbackSeo?.description
    const image =
        seo?.image?.url || defaults?.globalSeo?.fallbackSeo?.image?.url

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image?.replace(/&amp;/g, '&')} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta
                name="twitter:card"
                content={defaults?.globalSeo?.fallbackSeo?.twitterCard}
            />
            <meta
                name="google-site-verification"
                content="HUjvLR9uVCVeP-T1qx93nxMLRh2rMziZAt3G5V3qUGs"
            />
            {noIndex || defaults.noIndex ? (
                <meta name="robots" content="noindex" />
            ) : null}
            {defaults?.tags?.map(
                ({ tagName, attributes }, i) =>
                    tagName === 'link' && (
                        <link
                            key={i}
                            href={attributes?.href}
                            rel={attributes?.rel}
                            sizes={attributes?.sizes}
                        />
                    )
            )}
            {schema?.map((_schema, i) => (
                <script key={`schema_${i}`} type="application/ld+json">{`
                    ${JSON.stringify(_schema)}
                `}</script>
            ))}
        </Helmet>
    )
}

export default Meta
