import React, { createContext, useState, useContext } from 'react'

const ModalContext = createContext()

/**
 * ModalProvider
 *
 * @param {mixed} children
 */
const ModalProvider = ({ children }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalComponents, setModalComponents] = useState(null)
    const [activeModalComponent, setActiveModalComponent] = useState(false)

    return (
        <ModalContext.Provider
            value={{
                modalOpen,
                setModalOpen,
                modalComponents,
                setModalComponents,
                activeModalComponent,
                setActiveModalComponent,
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

/**
 * useModalState
 */
const useModalState = () => {
    const context = useContext(ModalContext)

    if (context === undefined)
        throw new Error('useModalState must be used within a ModalProvider')

    return context
}

export { ModalProvider, useModalState }
