import React from 'react'

import { BrowserProvider, useBrowserState } from './BrowserProvider'
import { MenuProvider, useMenuState } from './MenuProvider'
import { SearchProvider, useSearchState } from './SearchProvider'
import { LightboxProvider, useLightboxState } from './LightboxProvider'
import { ModalProvider, useModalState } from './ModalProvider'

const ContextProvider = ({ children }) => (
    <BrowserProvider>
        <MenuProvider>
            <SearchProvider>
                <LightboxProvider>
                    <ModalProvider>{children}</ModalProvider>
                </LightboxProvider>
            </SearchProvider>
        </MenuProvider>
    </BrowserProvider>
)

export {
    ContextProvider,
    useBrowserState,
    useMenuState,
    useSearchState,
    useLightboxState,
    useModalState,
}
