import React from 'react'

import { ContextProvider } from '@context'

import Header from '@components/Header'
import Footer from '@components/Footer'
// import CookieBanner from '@components/CookieBanner'
// import TwoTakeBanner from '@components/TwoTakeBanner'
import Lightbox from '@components/Lightbox'
import Modal from '@components/Modal'

const Root = ({
    showHeader = true,
    showFooter = true,
    globalConfig,
    locale,
    children,
    ...props
}) => (
    <ContextProvider>
        {showHeader ? <Header {...{ globalConfig, locale, ...props }} /> : null}
        {children}
        {/* <CookieBanner /> */}
        {/* <TwoTakeBanner /> */}
        <Lightbox />
        <Modal />
        {showFooter ? <Footer {...{ globalConfig }} /> : null}
    </ContextProvider>
)

export default Root
