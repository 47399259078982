export default [
    {
        name: 'English - United Kingdom',
        code: 'en-GB',
        label: 'English (UK)',
        localLabel: 'English (UK)',
        default: true,
    },
    {
        name: 'Chinese - China',
        code: 'zh-CN',
        label: 'Chinese, Simplified',
        localLabel: '简体中文',
        dateFormat: 'yyyy.MM.dd',
    },
    {
        name: 'Chinese - Taiwan',
        code: 'zh-HANT',
        label: 'Chinese, Traditional',
        localLabel: '繁體中文',
    },
    {
        name: 'English - Australian',
        code: 'en-AU',
        label: 'English (AU)',
        localLabel: 'English (AU)',
    },
    {
        name: 'English - United States',
        code: 'en-US',
        label: 'English (US)',
        localLabel: 'English (US)',
    },
    {
        name: 'French - France',
        code: 'fr-FR',
        label: 'French',
        localLabel: 'Français',
    },
    {
        name: 'German - Germany',
        code: 'de-DE',
        label: 'German',
        localLabel: 'Deutsch',
    },
    {
        name: 'Italian - Italy',
        code: 'it-IT',
        label: 'Italian',
        localLabel: 'Italiano',
    },
    {
        name: 'Japanese',
        code: 'ja-JP',
        label: 'Japanese',
        localLabel: '日本語',
        dateFormat: 'yyyy.MM.dd',
    },
    {
        name: 'Korean',
        code: 'ko-KR',
        label: 'Korean',
        localLabel: '한국어',
        dateFormat: 'yyyy.MM.dd',
    },
    {
        name: 'Portuguese - Brazil',
        code: 'pt-BR',
        label: 'Portuguese',
        localLabel: 'Português (BR)',
    },
    { name: 'Russian', code: 'ru-RU', label: 'Russian', localLabel: 'Русский' },
    {
        name: 'Spanish - Spain',
        code: 'es-ES',
        label: 'Spanish',
        localLabel: 'Español',
    },
    { name: 'Turkish', code: 'tr-TR', label: 'Turkish', localLabel: 'Türkçe' },
]
