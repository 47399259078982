import React, { useState, useEffect } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { saveAs } from 'file-saver'

import close from '@images/global/close.svg'
import interfaceArrowLeft from '@images/global/interface-arrow-left.svg'
import interfaceArrowRight from '@images/global/interface-arrow-right.svg'
import interfaceArrowDownload from '@images/global/interface-arrow-download.svg'

import { useLightboxState } from '@context'

import SVG from '@components/SVG'

import './style.scss'

const Lightbox = () => {
    const [active, setActive] = useState(false)
    const {
        lightboxOpen,
        setLightboxOpen,
        activeLightboxImage,
        setActiveLightboxImage,
        lightboxImages,
        setLightboxImages,
        lightboxVideo,
        setLightboxVideo,
    } = useLightboxState()

    const handleLightboxClose = () => {
        setLightboxOpen(false)

        setTimeout(() => {
            setLightboxImages([])
            setLightboxVideo(false)
        }, 250)
    }

    const handleKeyDown = ({ keyCode }) => {
        if (keyCode === 27) handleLightboxClose()
    }

    useEffect(() => {
        if (lightboxOpen) {
            setTimeout(() => {
                setActive(true)
            }, 150)
        } else {
            setActive(false)
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [lightboxOpen])

    return (
        <div
            className={`lightbox grid grid-cols-14 w-full h-full p-10 pb-20 xl:p-20 fixed top-0 left-0 bg-black bg-opacity-90 text-center text-white transition-all ${
                lightboxOpen
                    ? 'opacity-100 visible pointer-events-auto'
                    : 'opacity-0 invisible pointer-events-none'
            } z-50`}
        >
            {lightboxImages.length > 0 && (
                <div
                    className={`group hidden md:flex items-center justify-center cursor-pointer ${
                        activeLightboxImage === 0
                            ? 'opacity-20 pointer-events-none'
                            : 'opacity-100'
                    }`}
                    onClick={() =>
                        setActiveLightboxImage(
                            Math.max(0, activeLightboxImage - 1)
                        )
                    }
                >
                    <div className="flex items-center justify-center w-10 h-10 text-center">
                        <SVG
                            src={interfaceArrowLeft}
                            className="w-4 h-auto group-hover:text-yellow"
                        />
                    </div>
                </div>
            )}
            <div className="group col-span-full md:col-span-12 md:col-start-2 lightbox__inner flex items-center justify-center mx-auto w-full h-full top-0 left-0 relative">
                {lightboxImages.length > 0 && (
                    <>
                        {lightboxImages.map(({ full, alt }, i) => (
                            <>
                                <div
                                    className={`lightbox__image w-full h-full absolute top-0 left-0 transition-all duration-500 ${
                                        i === activeLightboxImage
                                            ? 'opacity-100 visible pointer-events-auto'
                                            : 'opacity-0 invisible pointer-events-none'
                                    }`}
                                    data-alt={alt}
                                >
                                    {active ? (
                                        <BackgroundImage
                                            fluid={full}
                                            className="w-full h-full absolute top-0 left-0 bg-contain bg-center bg-no-repeat"
                                            style={{ position: null }}
                                        />
                                    ) : null}
                                    {lightboxImages?.[activeLightboxImage]
                                        ?.filename ? (
                                        <button
                                            type="button"
                                            onClick={() =>
                                                saveAs(
                                                    lightboxImages?.[
                                                        activeLightboxImage
                                                    ]?.url,
                                                    lightboxImages?.[
                                                        activeLightboxImage
                                                    ]?.filename
                                                )
                                            }
                                            className="block absolute right-12 bottom-12 cursor-pointer z-60"
                                        >
                                            <span className="flex items-center justify-center w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/4 group-hover:-translate-y-1/2 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 rounded-full transition-all">
                                                <SVG
                                                    src={interfaceArrowDownload}
                                                    className="w-8 h-auto text-orange3"
                                                />
                                            </span>
                                        </button>
                                    ) : null}
                                </div>
                            </>
                        ))}
                    </>
                )}
                {lightboxVideo ? (
                    <div className="w-full pt-9/16 max-h-full relative">
                        <iframe
                            type="text/html"
                            src={`https://www.youtube-nocookie.com/embed/${lightboxVideo?.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
                            frameBorder="0"
                            allowfullscreen="allowfullscreen"
                            className="w-full h-full absolute top-0 left-0"
                        />
                    </div>
                ) : null}
            </div>
            {lightboxImages.length > 0 && (
                <div
                    className={`group hidden md:flex items-center justify-center cursor-pointer ${
                        activeLightboxImage === lightboxImages.length - 1
                            ? 'opacity-20 pointer-events-none'
                            : 'opacity-100'
                    }`}
                    onClick={() =>
                        setActiveLightboxImage(
                            Math.min(
                                lightboxImages.length - 1,
                                activeLightboxImage + 1
                            )
                        )
                    }
                >
                    <div className="flex items-center justify-center w-10 h-10 text-center">
                        <SVG
                            src={interfaceArrowRight}
                            className="w-4 h-auto group-hover:text-yellow"
                        />
                    </div>
                </div>
            )}
            <div className="flex items-center justify-end absolute top-4 right-4">
                <button
                    type="button"
                    className="flex items-center justify-center w-14 h-14 text-center"
                    onClick={() => handleLightboxClose()}
                >
                    <SVG src={close} className="w-6 h-auto text-white" />
                </button>
            </div>
        </div>
    )
}

export default Lightbox
